import React from 'react';
import styles from './Home.module.css';
import { NavLink } from "react-router-dom";
import Container from '../components/Container';
import Button from '../components/Button';

interface BLProps {
  children?: React.ReactNode,
}

const BackgroundLayer = ({ children }: BLProps) => {
  return (
    <div className={styles.BackgroundLayer}>
      {children}
    </div>
  )
}

const ContractBlock = () => {
  return (
    <section className={styles.ContractBlock}>
      <h2>Limit orders Smart Contract</h2>
      <p>Our Exchange works on open smart contracts, providing a new way to trade directly on-chain safely, cheaper and with zero operation errors. Blockchain network execute transactions with random, inpredictable latency giving a fair access to the market for every client around the Globe.</p>
      <ul>
        <li><a title='GitHub' href='https://github.com/mnickw/DistributedOrderBook/tree/alpha/contracts'>GitHub Repo</a></li>
        <li><a title='Etherscan' href='https://ropsten.etherscan.io/address/0x4143f6a3bd07f17f7115d724c45827ca428a5b9a'>Etherscan</a></li>
      </ul>
    </section>
  )
}

const WelcomeBlock = () => {
  return (
    <section className={styles.WelcomeText}>
      <h1>
        Exchange Crypto and Stocks,<br/>
        Access Securities on Blockchain<br />
        with our cutting edge technologies
      </h1>
      <p>Exchange Stocks and other Securities with Crypto or Fiat on fully regulated market. Get access to your assets on our Blockchain Depository, change brokers, use dApps or bridge with off-chain world.</p>
      <Button>Trade on testnet Broker</Button>
    </section>
  )
}

const RedTokenPromo = () => {
  return (
    <section className={styles.RedTokenBlock}>
      <img className={styles.RedBackground} src='red-background.svg' />
      <Container>
        <h1>Market liqudity Tokens</h1>
        <p>Swap RED Tokens with Crypto or Fiat. It provides market liqudity on the Exchange for each asset.</p>
        <p>We are planning to launch Token ICO on operational networks in December 2022.</p>
        <a title='Etherscan' href='https://ropsten.etherscan.io/address/0x1ab0c8d31f49c8bdf7d37f28aca34802dabf6dcc'>Testnet Deploy</a>
      </Container>
    </section>
  )
}


const Home = () => {
  return (
    <>
      <BackgroundLayer>
        <Container>
          <WelcomeBlock />
          <ContractBlock />
        </Container>
      </BackgroundLayer>
      <RedTokenPromo />
    </>
  )
}

export default Home;
