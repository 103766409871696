import React from 'react';
import styles from './Components.module.css';

interface ButtonProps {
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    locked?: boolean;
}

const Button = ({ children, onClick, locked }: ButtonProps) => {
    if (locked === undefined) locked = false;

    return (
        <button onClick={onClick} className={locked ? styles.ButtonLocked : styles.Button}>
            {children}
        </button>
    )
}

export default Button;
