import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import Home from './pages/Home';
import { Login, KYC } from './pages/Login';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from 'recoil';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <Web3Provider
      connectors={connectors}
      libraryName={'ethers.js'}
    > */}
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="auth" element={<Login />} />
          <Route path="kyc" element={<KYC />} />
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="listing" />
            {/* <Route path=":teamId" element={<Team />} />
            <Route path="new" element={<NewTeamForm />} />
            <Route index element={<LeagueStandings />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
    {/* </Web3Provider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
