import React from 'react';
import styles from './Components.module.css';

interface ContainerProps {
    children?: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
    return (
        <div className={styles.Container}>{children}</div>
    )
}

export default Container;
