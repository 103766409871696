import Web3 from 'web3';
import { atom, useSetRecoilState } from 'recoil';
import detectEthereumProvider from '@metamask/detect-provider';

const web3__state = atom<any | null>({
  key: 'web3',
  default: null,
  dangerouslyAllowMutability: true,
});

const account__state = atom<any | null>({
  key: 'account',
  default: null,
});

const auth__state = atom<any | null>({
  key: 'auth',
  default: false,
});

const targetNetworkId = '0x3';

type InjectedProvider = any;

function useWeb3() {
  const setWeb3 = useSetRecoilState(web3__state);
  const setAccount = useSetRecoilState(account__state);
  const setAuth = useSetRecoilState(auth__state);

  const checkIfChainIsCorrect = async (eth: InjectedProvider) => {
    const currentChainId = await eth.request({
      method: 'eth_chainId',
    });
    return (currentChainId === targetNetworkId);
  }

  const trySwitchChain = async (eth: InjectedProvider) => {
    await eth.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: targetNetworkId }],
    });
  }

  const authWeb3 = async () => {

    // We support inject providers only for now
    if (window.ethereum) {

      const eth: InjectedProvider = window.ethereum;
      const web3 = new Web3(eth);

      const chainCorrect = await checkIfChainIsCorrect(eth);

      if (!chainCorrect) {
        await trySwitchChain(eth);
      }

      // Request account access
      let accounts = await eth.request({ 
        method: 'eth_requestAccounts' 
      });

      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }

      await eth.enable();
      return web3;

    } else {
      throw Error("Only Metamask is supported!");
    }
  }

  const activateWeb3 = async () => {
    const web3 = await authWeb3();
    setWeb3(web3);
    setAuth(true);
  };

  return [activateWeb3];
}


export { useWeb3, auth__state, web3__state, account__state }