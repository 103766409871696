import React, { useEffect, useState } from 'react';
import styles from './Login.module.css';
import { Navigate, useNavigate } from "react-router-dom";
import Container from '../components/Container';
import Button from '../components/Button';
import background from './auth-background.svg';
import { useWeb3 } from '../web3/connectors';


interface AuthFormBlockProps {
    name: string;
    children?: React.ReactNode;
}

const AuthFormBlock = ({ name, children }: AuthFormBlockProps) => {
    return (
        <div className={styles.AuthContainerHolder}>
            <Container>
                <div className={styles.AuthContainer}>
                    <h1>CSE | Online Broker</h1>
                    <div className={styles.FormBlock}>
                        <img src={background} className={styles.AuthBackground} />
                        <h2>{name}</h2>
                        {children}
                    </div>
                    <span className={styles.CorpTitle}>Crypto Securities Exchange, 2022</span>
                </div>
            </Container>
        </div>
    )

}

export const Login = () => {

    const navigate = useNavigate();
    const [error, setError] = useState<any>(undefined);
    const [activateWeb3] = useWeb3();

    const web3Auth = () => {
        activateWeb3().then(
            _ => {
                navigate('../kyc');
            },
            err => {
                setError(err)
            }
        )
    }

    useEffect(() => {
        if (error === null) {
            console.log('ok');
        } else if (error !== undefined) {
            console.error(error);
        }
    }, [error])

    return (
        <AuthFormBlock name="Secure Login">
            {(error !== undefined && error !== null) ? (
                <p>{(!!error.message) ? error.message : error.toString()}</p>
            ) : null}
            <Button onClick={web3Auth}>Use Web3</Button>
            <Button locked={true}>Use Account</Button>
        </AuthFormBlock>
    )
}

export const KYC = () => {

    const navigate = useNavigate();

    return (
        <AuthFormBlock name="KYC Process">
            <p>Not required</p>
            <Button onClick={_ => navigate('../')}>Continue</Button>
        </AuthFormBlock>
    )
  }