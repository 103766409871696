import React from 'react';
import styles from './App.module.css';
import { NavLink, Outlet } from "react-router-dom";
import Container from '../components/Container';
import notice from './notice.svg'
import { auth__state } from '../web3/connectors';
import { useRecoilState } from 'recoil';


const TopNotify = () => {
  return (
    <div className={styles.TopNotify}>
      <Container>
        <img alt='Notice msg' src={notice} />
        <p>We are building and testing infrastructure and operate only in Demo mode on Test network</p>
      </Container>
    </div>
  )
}

const PageHead = () => {

  const [isLoggedIn, setLoggedIn] = useRecoilState(auth__state);

  return (
      <header className={styles.Header}>
        <h1>Crypto Securities Exchange</h1>
        <ul>
          <li>
            <NavLink to='listing'>Token Listing</NavLink>
          </li>
          <li>{(isLoggedIn) ? (
              <a onClick={() => setLoggedIn(false)}>Log out</a>
            ) : (
            <NavLink to='auth'>Login</NavLink>
          )}</li>
        </ul>
      </header> 
  )
}

const Footer = () => {
  return (
      <section className={styles.Footer}>
        <Container>
          <p>This is a MVP implementation for Exchnage listing and Online Broker. Final product will differ.<br />
          For contact mail to <a href='mailto:contact@chaindepo.org'>contact@chaindepo.org</a></p>
          <p>Crypto Securities Exchange, 2022</p>
        </Container>
      </section>
  )
}

function App() {
  return (
    <div className={styles.App}>
      <TopNotify />
      <Container>
        <PageHead />
      </Container>
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
